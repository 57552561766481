import React, {useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";
import HtmlParser from "react-html-parser";

function Demandfeatures({data}) {
    const [toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    const getActiveClass = (index, className) =>
        toggleState === index ? className : "";

    return (
        <section className="tekrevol_gray_bg p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.industries_body_txt}>
                            <h2>{checkData(data, 'industries_title')}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div>{HtmlParser(checkData(data, 'industries_description'))}</div>
                    </Col>
                </Row>
                <Row>
                    {data?.industries_tabs?.map((value, i) => (
                        <Col xs={12} md={2} lg={2} xl={2} xxl={2}>
                            <div key={i} className={`industries_boxes hover01 ${getActiveClass(i, "active-tabs")}`}
                                 onClick={() => toggleTab(i)}>
                                <figure>
                                    <img
                                        src={`${mediaBaseURL}${checkData(value, 'image')}`}
                                        className="img-fluid "
                                        alt="image_industries"/>
                                </figure>
                                <h6>{value.title}</h6>
                            </div>
                        </Col>
                    ))}
                    {data?.industries_tabs?.map((value, i) => (
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                            <div key={i}
                                 className={toggleState === i ? 'industries_content d-block' : "content d-none"}
                            >{HtmlParser(checkData(value, 'description'))}</div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
}

export default Demandfeatures;